<template>
  <div>
    <section>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="background: #0ABAB5; border-color: #0ABAB5; color: #ffffff"
            @click="saveData"
        >
            Save
        </button>
        <button
            class="uk-button uk-button-default uk-border-rounded uk-margin-right"
            style="border-color: #0ABAB5; color: #0ABAB5"
            @click="$emit('action', false)"
        >
            Cancel
        </button>
    </section>
    <section class="uk-margin">
      <div
        class="uk-grid-collapse"
        style="padding: .2rem 0"
        uk-grid
        v-for="(detail, dKey) in details"
        :key="dKey"
      >
        <div class="uk-width-1-2 uk-margin-auto-vertical">{{ detail.label }}</div>
        
        <div class="uk-width-1-2">
          <div class="uk-grid-collapse" uk-grid>
            <div class="uk-width-expand">
              <input
                  v-model="form[detail.value]"
                  class="uk-input"
                  type="text"
                >  
            </div>
          </div>
          <div v-if="errorsD[detail.value]" class="uk-text-danger">{{ errorsD[detail.value] }}</div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { notificationSuccess } from '@/utils/notification';

export default {
    props: {
        data: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            isLoading: false,
            details: [
                { label: 'Laptop', value: 'laptop', type: "text" },
                { label: 'Kendaraan bermotor', value: 'vehicle', type: "text" },
            ],
            form: { ...this.$props.data },
            errorsD: {},
        };
    },

    methods: {
        ...mapActions({
            patchEquipment: 'mitra/patchEquipment',
        }),

        async saveData() {
            this.isLoading = true;
            
            const nForm = {
                "detail": {
                    "equipment": {
                        "laptop": !this.form.laptop || this.form.laptop === ""
                            ? null : this.form.laptop,
                        "vehicle": !this.form.vehicle || this.form.vehicle === ""
                            ? null : this.form.vehicle
                    }
                }
            };

            await this.patchEquipment({ user_id: this.$route.params.mitra_id, payload: nForm });
            notificationSuccess('Update saved!');

            this.isLoading = false;
            this.$emit('action', false);
        }
    }
};
</script>

<style scoped>
  .avatar {
    width: 100px;
    height: 100px;
    border: 0;
  }
</style>
