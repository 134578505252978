<template>
  <div>
    <equipmentEdit v-if="isEdit" :data="data" @action="action" />
    <equipmentView v-else @passData="passData" @action="action" />
  </div>
</template>

<script>
import equipmentView from "@/components/pages/admin/workers/detail-worker/view/equipment.vue";
import equipmentEdit from "@/components/pages/admin/workers/detail-worker/edit/equipment.vue";

export default {
    components: { equipmentView, equipmentEdit },
    data() {
        return {
            isEdit: false,
            data: {},
        };
    },

    methods: {
        action(isEdit) {
            this.isEdit = isEdit;
        },

        passData(data) {
            this.data = data;
        }
    }
};
</script>
